const copy = {
    App: {
        name: "Alex 'AVH' Vincent-Hill",
        cta: 'partner with me',
    },
    Nav: {
        home: 'home',
        projects: 'projects',
        blog: 'blog',
        resume: 'resume',
    },
    NavIcons: {
        email: 'alexanderjvincenthill@gmail.com',
        linkedInUrl: 'https://www.linkedin.com/in/avincenthill/',
        githubUrl: 'https://github.com/avincenthill',
    },
    NotFound: {
        notFound: '404 not found',
    },
    Resume: {
        downloadName: `Alex Vincent-Hill's Resume ${new Date()}.pdf`,
    },
    Blog: {
        disclaimer:
            'opinions expressed are solely my own and do not express the views or opinions of any employer',
    },
    PayPal: {
        cta: 'buy me a coffee',
        email: 'alexanderjvincenthill@gmail.com',
    },
    YT: {
        authKey: 'AIzaSyBCdXhhkF8p1w-SDM0GFiia2Huhz5WQyR8',
        selectedVideos: 'videos',
        selectedChannels: 'channels',
        cta: 'please select content',
        isError:
            'there was an error retrieving videos, probably due to API quota limitations',
    },
    Flashcards: {
        vocabularySpreadsheetID: '1cdq-mIHfnEL0B2ZJVpvNdsqlAOPg9DL8vb83RcW7-ZY',
        authKey: 'AIzaSyBuegFgkUfKyrPyspGJjC3S5Q1do4PyIsM',
        isError:
            'se ha producido un error al recuperar los datos de las flashcards, probablemente debido a las limitaciones de cuota de la API',
        isLoading: 'los datos de las flashcards se están cargando...',
        mode: 'modo',
        es: 'español',
        en: 'inglés',
        card: 'tarjeta',
        word: 'palabra',
        lastUpdated: 'fecha',
        cyclingInProgress: 'ciclando',
        notCycling: 'no ciclar',
        cycle: 'ciclar',
        restart: 'reiniciar',
        seconds: 'segundos',
        each: 'cada',
        remaining: 'restantes',
    },
    Workout: {
        reset: 'reset',
        print: 'print',
    },
    Timer: {
        start: 'click to start',
        reset: 'click to reset',
    },
};

export default copy;
