import React, { lazy } from 'react';
import styled from 'styled-components';

const Header = lazy(() => import('../Header/Header'));

interface ComponentProps {
    className: string;
    children: React.ReactNode;
    isAlignedLeft?: boolean;
}

const StyledMain = styled.main`
    width: auto;
    padding: calc(var(--avhgrid) * 10);
    padding-left: calc(var(--avhgrid) * 20);
    @media (max-width: 768px) {
        padding: calc(var(--avhgrid) * 5);
        padding-left: calc(var(--avhgrid) * 10);
    }
    overflow-x: hidden;
`;

interface IStyledPage {
    isAlignedLeft: boolean;
}
const StyledPage = styled.div<IStyledPage>`
    min-width: 100vw;
    max-width: ${props =>
        props.isAlignedLeft ? 'calc(var(--avhgrid)*300)' : '100vw'};
`;

function Page({ className, children, isAlignedLeft = false }: ComponentProps) {
    return (
        <StyledPage isAlignedLeft={isAlignedLeft} className={className}>
            <Header />
            <StyledMain>{children}</StyledMain>
        </StyledPage>
    );
}

export default Page;
