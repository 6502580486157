const projects = [
    {
        title: 'AI-AVH ChatBot',
        key: 'chat',
        description: 'Prompt engineering meets recruiting',
        longDescription: `PERSONAL PROJECT: This is a very simple chatbot that hits a groq API from the client and starts a conversation with some prompt engineering to make it's answers helpful to recruiters.`,
        emoji: '🤖',
    },
    {
        title: 'Workout Tracker',
        key: 'workout',
        description:
            "A tool I can use to track what exercises I've done during a workout",
        longDescription: `PERSONAL PROJECT: I wanted to replace my physical notebook that I use to track what I do during routine workouts. This tool is a work in progress and styled mobile-first!`,
        emoji: '🏋️‍♂️',
    },
    {
        title: 'Spanish Flashcards',
        key: 'flashcards',
        description:
            "Flashcards of words I've recorded during Spanish lessons to help with retention",
        longDescription: `PERSONAL PROJECT: I wanted a way to render and use my spreadsheet of Spanish vocabulary as flashcards. This project uses the Google Sheets API.`,
        emoji: '🇪🇸',
    },
    {
        title: 'YT Content Tool',
        key: 'yt',
        description:
            'Quickly view most recent videos from channels by category',
        longDescription: `PERSONAL PROJECT: I wanted a better way to filter my YouTube subscriptions and see my favorite channels' newest videos aggregated by content type. Try not to exceed my API quota please!`,
        emoji: '▶️',
    },
    {
        title: 'Particle Simulation',
        key: 'particle',
        url: 'https://github.com/avincenthill/particle_simulation',
        description: 'A particle system simulation in Processing',
        longDescription: `PERSONAL PROJECT: Modeling chemical and physical reactions (chemical equilibria, fission, classical collisions, etc) with Processing/Java and rendering the simulation using PeasyCam. This link will lead to the source code, and you will need to install Processing to run it.`,
        emoji: '🔬',
    },
    /*
  TBD: need to find the updated link for this work
  {
    title: "Media Automation",
    key: "media",
    url: "https://marketingplatform.google.com/about/resources/fetch-and-aeg-presents-boost-efficiency-with-structured-information-files/",
    displayUrl: "marketingplatform.google.com",
    description: "100x'ing Google Ads campaign creation",
    longDescription: `WORK PROJECT (FETCH INC.): Unprecedented work that I led which automated the creation of digital ad campaigns and featured in a case study written by Google.`,
    emoji: "⚙️",
  },
  */
    {
        title: 'Leetcode Answers',
        key: 'leetcode',
        url: 'https://leetcode.com/avincenthill/',
        description: 'My progress through LeetCode questions',
        longDescription: `PERSONAL PROJECT: This represents the practice I've done with algorithm questions on leetcode.com, mostly in JavaScript.`,
        emoji: '🧩',
    },
    {
        title: 'Online Chess',
        key: 'chess',
        url: 'https://lichess.org/@/avincenthill/perf/rapid',
        description: 'Challenge me on lichess.org',
        longDescription: `PERSONAL PROJECT: I like to play chess in my spare time - here is a link to my profile on lichess.org!`,
        emoji: '♟️',
    },
    {
        title: 'Educate Yourself',
        key: 'educate',
        url: 'http://shirt.woot.com/offers/educate-yourself',
        description: 'Graphic design for woot.com',
        longDescription:
            'ART PROJECT: A liberty themed t-shirt design following the Snowden NSA leaks.  To date it is my most popular graphic design, with over 3,000 sold!',
        emoji: '🖼️',
    },
    {
        title: 'Grand Hilbert',
        key: 'hilbert',
        url: 'https://www.woot.com/offers/hilberts-grand-infinite-hotel-2',
        description: 'Graphic design for woot.com',
        longDescription:
            "ART PROJECT: A Wes Anderson-inspired math joke t-shirt design about the number theory thought-experiment: Hilbert's Paradox of the Grand Hotel.",
        emoji: '🖼️',
    },
    {
        title: 'Timer',
        key: 'timer',
        description: 'A timer counter in React.js',
        longDescription:
            'Personal Project: A timer that I was asked to implement in an interview',
        emoji: '⏰',
    },
];

export default projects;
